.modal {
  background-color: white;
  border-radius: 5px;
  position: absolute;
  top: 51px;

  z-index: 20;
  overflow: hidden;
  min-width: 100%;
  right: 0;
  box-shadow: var(--box-shadow-1);
  color: black;
  cursor: default;
  list-style: none;
  margin: 0;
  padding: 0;
}

.profileModalBody {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-color-1);
}

.button:hover {
  background-color: var(--primary-color-4-opacity);
}

.buttonLogout {
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 14px;

  white-space: nowrap;
  color: var(--primary-color-1);
  font-weight: 600;
}

.buttonLogout:hover {
  background-color: var(--primary-color-4-opacity);
}
