.navBarContainer {
  width: 100%;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
}

.leftHandSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1em;
}

.logoDiv {
  cursor: pointer;
}

.logo {
  max-width: 7em;
}

.authActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 1rem;
}

.authenticate {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color-1);
}

.authenticate:hover {
  color: var(--primary-color-2);
}

.button {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: none;
}

.navBarButton {
  display: inline-block;
  vertical-align: top;
  font-size: 15px;
  cursor: pointer;
}

.profileButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0.5em;
  cursor: pointer;
  border: 1px solid var(--primary-color-1);
  padding: 10px 20px;
  border-radius: 9999px;
  position: relative;
  transition: all 0.3s ease;
}

.profileButton:hover {
  background-color: var(--primary-bg-light);

  color: white;
}

/* .profileButton:active {
  transform: scale(0.95);
} */

.name {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
}

.nameButton {
  font-size: 16px;
  font-weight: 500;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
  background-color: transparent;
  border: none;
  transition: all 0.3s ease;
}

.nameButton:hover {
  color: var(--primary-color-2);
  scale: 1.05;
}

.nameButton:active {
  scale: 0.95;
}

.iconContainer {
  background-image: linear-gradient(
    45deg,
    var(--primary-color-4),
    var(--primary-color-4-opacity-15)
  );
  border-radius: 9999px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.iconContainer:hover {
  background-color: var(--primary-color-4-opacity);
  transform: scale(1.05);
}

.iconContainer:active {
  transform: scale(0.95);
}

.profile {
  height: 50%;
  width: 50%;
}

.hamburger {
  display: none;
}

@media (max-width: 1140px) {
  .navBarContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 600px) {
  .nameButton {
    display: none;
  }

  .name {
    font-size: 14px;
  }

  .profileButton {
    padding: 10px 15px;
  }

  .authenticate {
    display: none;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid var(--primary-color-1);
  }

  .iconBurger {
    width: 20px;
    height: 20px;
  }
}
