.footer {
  padding: 2.5rem 0rem;
  background-color: var(--primary-color-1);
  margin-top: 2.5rem;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.topSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
}

.left {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.link {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.link:hover {
  opacity: 0.7;
}

/* Right */

.right {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.colHeader {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: white;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.colLinks {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link1 {
  font-size: 12px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.link1:hover {
  opacity: 0.7;
}

/* bottomSection  */

.bottomSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1.5rem;
}

.logoDiv {
  cursor: pointer;
  background-color: white;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 70px;
  width: 70px;
}

.logoPart1 {
  font-size: 80%;
  font-weight: 400;
  color: var(--primary-color-1);
}

.logoPart2 {
  font-size: 80%;
  font-weight: 700;
  color: var(--primary-color-1);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
}

.logoText {
  font-size: 14px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.col1 {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.subText {
  font-size: 12px;
  font-weight: 400;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
}

.copyRight {
  font-size: 12px;
  font-weight: 200;
  color: white;
}

@media (max-width: 1140px) {
  .footerContainer {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 600px) {
  .topSection {
    flex-direction: column;
    gap: 2.5rem;
  }

  .footerContainer {
    gap: 2.5rem;
  }

  .link {
    white-space: nowrap;
  }

  .left {
    overflow-x: scroll;
  }

  .bottomSection {
    flex-direction: column;
    gap: 1.5rem;
  }
}
