.main {
  display: flex;
  flex-direction: column;
  row-gap: 3.5rem;
  padding-top: 2rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  column-gap: 3rem;
  width: 100%;
}

.leftSection {
  width: 50%;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.leftSectionGap {
  width: 50%;
  min-width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.header {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;

  margin-bottom: 0rem;
  color: var(--primary-color-1);
}

.underline {
  font-size: 30px;
  font-weight: 600;
  width: fit-content;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
  border-bottom: 3px solid var(--primary-color-3);
}
.gradient {
  font-size: 40px;
  font-weight: 700;
  line-height: 150%;
  background: linear-gradient(
    to bottom right,
    /* Specify the direction here */ var(--primary-color-1),
    var(--primary-color-2),
    var(--primary-color-3)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subHeader {
  font-size: 20px;
  font-weight: 400;
  color: var(--primary-color-1);
  line-height: 150%;

  margin-bottom: 0rem;
}

.rightSection {
  position: relative; /* Add this */
  display: flex;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  width: 50%;
}

.logo {
  background-color: white;
  box-shadow: var(--box-shadow-2);
  max-width: 100%;
  padding: 2rem 3rem;
  border-radius: 10px;
  border: 1px dashed var(--primary-color-4);
  background: var(--primary-color-4-opacity-15);
}

.centerSection {
  max-width: 50%;
  display: flex;
  row-gap: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sectionParagraph {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.title {
  font-size: 30px;
  font-weight: 600;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
}

.description {
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
}

.maxWidth {
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 3.5rem;
  width: 100%;
  margin-right: auto;
}

.sectionPadded {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  background-color: var(--primary-color-4-opacity-15);
  border-radius: 10px;
  border: 1px solid var(--primary-color-4-opacity);
}

.dream {
  width: 80%;
}

.benefitsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 0;
  margin: 0;
}

.buttonContainer {
  margin-top: 2rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  padding: 0;
  margin: 0;
}

.gridItem {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 1rem;
  transition: all 0.3s;
  width: 100%;
  border: 1px solid var(--primary-color-4);
  background-color: white;
  padding: 1rem;
  height: 100%;
}

.gridTitle {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
}

.gridParagraph {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-color-2);
  margin-bottom: 0rem;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-color: var(--primary-color-4);
}

.headerSmall {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-bottom: 0rem;
}

.muteText {
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
}

.icon {
  width: 50%;
  height: 50%;
  color: var(--primary-color-1);
}

.callToAction {
  display: flex;

  align-items: center;
  justify-content: center;
  text-align: center;
}

@media (max-width: 600px) {
  .row {
    flex-direction: column-reverse;
    padding-left: 1rem;
    padding-right: 1rem;
    row-gap: 2rem;
  }

  .rightSection {
    width: 100%;
  }

  .leftSection {
    width: 100%;
  }

  .gradient {
    font-size: 30px;
    margin-bottom: 0rem;
  }

  .underline {
    font-size: 20px;
    line-height: 150%;
  }

  .maxWidth {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .sectionPadded {
    flex-direction: column;
    row-gap: 2rem;
  }

  .title {
    font-size: 22px;
    line-height: 150%;
  }

  .leftSectionGap {
    width: 100%;
  }
  .buttonContainer {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  .centerSection {
    max-width: 100%;
    row-gap: 1rem;
  }
  .benefitsGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
