.main {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;

  padding-bottom: 3rem;

  gap: 2rem;
}

.main h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--primary-color-1);
}

.main h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1rem;
  color: var(--primary-color-1);
}

.main p {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 2rem;
  line-height: 180%;
  color: var(--primary-color-2);
}

.lastUpdated {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: var(--primary-color-2);
}

.main ul {
  list-style: decimal;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 2rem;
  line-height: 180%;
  color: var(--primary-color-2);
}
