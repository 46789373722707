.main {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  max-width: var(--max-width);
  margin-left: auto;
  gap: 2rem;
  min-height: 50vh;
  margin-right: auto;
}

.rightSection {
  position: relative; /* Add this */
  display: flex;
  height: 300px;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  width: 40%;
}

.header {
  font-size: 55px;
  font-weight: 700;
  margin-bottom: 0rem;
  color: var(--primary-color-1);
}

.subHeader {
  font-size: 20px;
  font-weight: 400;
  color: var(--primary-color-1);
  line-height: 150%;
  margin-bottom: 0rem;
}

.buttons {
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
}

.svgContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSection {
  /* width: 50%;
  min-width: 50%; */
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 0rem;
}

.dashedCircle {
  stroke: var(--primary-color-4);
  stroke-width: 2;
  position: absolute;
  fill: none;
  stroke-dasharray: 10, 10;
  stroke-dashoffset: 0;
  animation: dash 1s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 20; /* Animate the dash */
  }
}
.logoContainer {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  /* background: linear-gradient(
    to bottom right,
    var(--primary-color-1),
    var(--primary-color-2),
    var(--primary-color-3),
    var(--primary-color-4)
  ); */
  background-color: white;
  font-weight: 800;
  border-radius: 9999px;
  color: var(--primary-color-3);
  font-size: 180%;
  box-shadow: var(--box-shadow-1);
}

.small {
  font-weight: 300;
}
.platform {
  position: absolute; /* Change to absolute */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  background-color: white;

  box-shadow: var(--box-shadow-1);
  border-radius: 9999px;
  height: 60px;
  /* Remove any margin, padding that might affect positioning */
}

.imgPlatform {
  object-fit: scale-down;
  height: 40px;
  max-width: 50px;
}

@media (max-width: 600px) {
  .main {
    flex-direction: column;
    gap: 2rem;
    padding-top: 5rem;
  }

  .rightSection {
    width: 100%;
    height: 420px;
  }

  .leftSection {
    width: 100%;
  }
}
