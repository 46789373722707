.layout {
  background-color: var(--primary-bg);
  min-height: 100vh;
}

.navBar {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  z-index: 100;
  background-color: var(--primary-bg);
}

.mainContent {
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 75px - 271px - 2.5rem);
}

.white {
  background-color: white;
}

.grey {
  background-color: var(--primary-bg);
}
