@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Ultalight.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Ultalight.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Light.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Light.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Thin.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Thin.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Regular.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Regular.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-SemiBold.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-SemiBold.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Bold.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Bold.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Heavy.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Heavy.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "gilroy";
  font-display: swap;
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Black.ttf")
    format("truetype");
  src: url("https://ik.imagekit.io/kuj9zqlrx0or/Static/newish/fonts/gilroy/Gilroy-Black.ttf")
    format("truetype");
  font-style: normal;
  font-weight: 800;
}

body,
html {
  height: 100%;
  font-family: "gilroy", sans-serif;
  font-display: swap;
  --bs-gutter-x: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #1f2544;
  font-family: "gilroy", sans-serif !important;
}

button {
  font-family: inherit; /* Add this line */
}

body,
input,
textarea,
button {
  font-family: "gilroy", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-green-700: #163116;
  --primary-green-600: #2c612c;
  --primary-green-500: #429242;
  --primary-green-400: #63b863;
  --primary-green-300: #8aca8a;
  --primary-green-200: #b1dbb1;
  --primary-green-100: #e8f5e8;

  --primary-dark-green-700: #031109;
  --primary-dark-green-600: #052313;
  --primary-dark-green-500: #08341c;
  --primary-dark-green-400: #0a4525;
  --primary-dark-green-300: #18a357;
  --primary-dark-green-200: #43e48c;
  --primary-dark-green-100: #a1f1c6;

  --primary-color-1: #1f2544;
  --primary-color-1-opacity: rgba(31, 37, 68, 0.4);
  --primary-color-1-opacity-15: rgba(31, 37, 68, 0.15);

  --primary-color-2: #474f7a;
  --primary-color-2-opacity: rgba(71, 79, 122, 0.4);
  --primary-color-3: #81689d;
  --primary-color-3-opacity: rgba(129, 104, 157, 0.4);
  --primary-color-4: #ffd0ec;
  --primary-color-4-opacity: rgba(255, 208, 236, 0.4);
  --primary-color-4-opacity-15: rgba(255, 208, 236, 0.14);

  --info-color-1: #d0ecff;

  --primary-red-700: #311616;
  --primary-red-600: #612c2c;
  --primary-red-500: #924242;
  --primary-red-400: #b86363;
  --primary-red-300: #ca8a8a;
  --primary-red-200: #dbb1b1;
  --primary-red-100: #edd8d8;

  --primary-blue-700: #162431;
  --primary-blue-600: #2c4761;
  --primary-blue-500: #426b92;
  --primary-blue-400: #638eb8;
  --primary-blue-300: #8aaaca;
  --primary-blue-200: #b1c6db;
  --primary-blue-100: #d8e3ed;

  --primary-bg: #f5f5f5;
  --primary-bg-light: #fafafa;
  --primary-bg-dark: #d6d6d6;
  --secondary-gray: #e8edf2;
  --secondary-gray-darker: #d5dee7;

  --text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);

  --primary-border: #e4e5e7;
  --primary-border-light: rgb(242 244 249);
  --box-shadow-1: rgba(47, 48, 97, 0.12) 0px 6px 12px;
  --box-shadow-2: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  --box-shadow-3: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

  --text-main: #222325;
  --text-body: #62646a;
  --text-mute: #95979d;

  --space-mid: 1rem;
  --max-width: 1140px;
}
