.maxWidth {
  max-width: var(--max-width);
  display: flex;
  flex-direction: column;
  margin-left: auto;
  gap: 3rem;
  padding: 5rem 5rem;
  width: 100%;
  margin-right: auto;
}

.header {
  font-size: 40px;
  font-weight: 500;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
  text-align: center;
  line-height: 150%;
}

.marketplaces {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));

  gap: 2rem;
  padding: 0;
  margin: 0;
}

.col {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  gap: 1rem;
  transition: all 0.3s;
  width: 100%;
  aspect-ratio: 1/1;

  height: 100%;
}

.marketplace {
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--box-shadow-3);
  padding: 0.5rem 0.5rem;
  gap: auto;

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.hover:hover {
  background-color: var(--primary-color-4-opacity-15);
  cursor: pointer;

  transition: all 0.3s;
  transform: scale(1.05);
  box-shadow: var(--box-shadow-2);
}

.hover:active {
  cursor: pointer;

  transition: all 0.3s;
  transform: scale(1);
  box-shadow: var(--box-shadow-3);
}

.comingSoon {
  color: var(--primary-color-1-opacity);
  font-size: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.marketplaceIcon {
  object-fit: scale-down;
  max-width: 70%;
  max-height: 70%;
}

.marketplaceIconDisabled {
  object-fit: scale-down;
  max-width: 70%;
  max-height: 70%;
  filter: grayscale(100%);
}

.iconContainer {
  height: 100%;
  max-height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketplaceName {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  margin-top: auto;
  color: var(--primary-color-1);
  margin-bottom: 0rem;
}

@media (max-width: 600px) {
  .marketplaces {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .marketplace {
    padding: 1rem;
  }

  .header {
    font-size: 25px;
  }

  .maxWidth {
    padding: 3rem 1rem;
  }
}
