.button {
  background-image: linear-gradient(
    var(--primary-color-1),
    var(--primary-color-2)
  );
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-family: "gilroy";
  font-weight: 500;
}

.button:hover {
  transform: scale(1.02);
  opacity: 0.8;
}

.button:active {
  transform: scale(0.98);
}

/* colors */

.primary-1 {
  background-image: linear-gradient(
    to right bottom,
    var(--primary-color-1) 0%,
    var(--primary-color-2) 100%
  ) !important;
  color: white !important;
}

.primary-2 {
  background-image: linear-gradient(
    to right bottom,
    var(--primary-color-2) 80%,
    var(--primary-color-3) 100%
  ) !important;

  color: white !important;
}

.primary-3 {
  background-image: linear-gradient(
    to right bottom,
    var(--primary-color-3) 0%,
    var(--primary-color-3) 50%,
    var(--primary-color-2) 100%
  ) !important;
  color: white !important;
}

.primary-4 {
  background-image: linear-gradient(
    var(--primary-color-4),
    var(--primary-color-4-opacity)
  ) !important;
  color: var(--primary-color-1) !important;
}

.primary-5 {
  background-image: none;
  background-color: transparent;
  border: 1px solid var(--primary-color-1) !important;
  color: var(--primary-color-1) !important;
}
.primary-6 {
  background-image: none;
  background-color: #ff6666;
  border: 1px solid #ff6666 !important;
  color: white !important;
}

.round {
  border-radius: 30px;
}

/* sizes */
.size-1 {
  padding: 10px 20px;
  font-size: 16px;
}

.size-2 {
  padding: 10px 20px;
  font-size: 14px;
}

.size-3 {
  padding: 7px 15px;
  font-size: 12px;
}

.unavailable {
  opacity: 0.4;
  cursor: not-allowed !important;
}
