.heroContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem;
  background-color: var(--primary-bg);
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  column-gap: 1.5rem;
  max-width: var(--max-width);
}

.header {
  font-size: 50px;
  margin-bottom: 0rem;
  line-height: 150%;
}

.subHeader {
  font-size: 20px;
  margin-bottom: 2rem;
  font-weight: 400;
  /* color: var(--primary-color-1-opacity) !important; */
  margin-bottom: 0rem;
}

.buttonContainer {
  margin-top: 2rem;
}

.leftSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  row-gap: 1rem;
}

.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}

.heroImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 600px) {
  .heroContainer {
    padding: 3rem 1rem;
  }

  .row {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .leftSection {
    width: 100%;
  }

  .rightSection {
    width: 100%;
  }

  .header {
    font-size: 30px;
  }

  .subHeader {
    font-size: 20px;
    line-height: 150%;
  }
}
